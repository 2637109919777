<template>
    <v-container fluid class="px-8" >
        <v-row>
            <v-col class="d-flex" >
                <v-btn
                    tile
                    dark
                    color="cyan darken-1"
                    class="font-weight-bold text-body-1 mb-6"
                    @click.stop="openEditPaperDlg()"
                >
                    <v-icon left>
                        mdi-plus-circle-outline
                    </v-icon>
                    创建试卷
                </v-btn>
            </v-col>
        </v-row>

        <!-- 课程列表 -->
        <v-data-table
            :headers="headers"
            :items="datas"
            :items-per-page-options="[5, 10, 15, -1]"
            class="elevation-2 row-pointer"
            :hide-default-footer="datas.length <= 10"
            :footer-props="{
                showFirstLastPage: true,
                itemsPerPageText: '每页行数',
                pageText: '',
                'items-per-page-all-text': '全部',
            }"
            @click:row="clickRow"
        >
            <template v-slot:no-data>
                暂无试卷
            </template>
            <template v-slot:item.name="{ item }">
                <div class="td-line font-weight-medium">
                    <!-- <div class="active-name" @click.stop="goto(item.paper_id)" > -->
                    <div class="active-hover" @click.stop="goto(item.paper_id)" >
                        {{ item.name }}
                    </div>
                </div>
            </template>
            <template v-slot:item.operate="{ item }">
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-bind="attrs" v-on="on"
                            class="mx-1"
                            icon
                            height="32"
                            width="32"
                            outlined
                            x-small
                            elevation="2"
                            color="purple"
                            @click.stop="openEditPaperDlg(item)"
                        >
                            <v-icon dark> mdi-pencil </v-icon>
                        </v-btn>
                    </template>
                    <span>编辑试卷信息</span>
                </v-tooltip>
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-bind="attrs" v-on="on"
                            class="mx-1"
                            icon
                            height="32"
                            width="32"
                            outlined
                            x-small
                            elevation="2"
                            color="red darken-1"
                            @click.stop="openConfirmDlg(item.paper_id)"
                        >
                            <v-icon dark> mdi-trash-can </v-icon>
                        </v-btn>
                    </template>
                    <span>删除试卷</span>
                </v-tooltip>
            </template>
        </v-data-table>

        <!-- 创建/编辑试卷 -->
        <EditPaperDlg ref="editPaperDlg" @submit="submitPaper" />

        <ConfirmDlg
            ref="delPaperConfirmDlg"
            title="删除试卷"
            text="确认 删除 当前试卷么？"
            keyword=" 删除 "
            @confirm="delPaperConfirm"
        />

    </v-container>
</template>

<script>
import EditPaperDlg from '@/views/components/dialog/EditPaperDlg';
import ConfirmDlg from '@/views/components/dialog/ConfirmDlg';

import { getPapers, editPaper, delPaper } from "@/api/admin_exam";
import { getTypeColor } from '@/utils/common';
import { getTypeFirstLetter } from "@/utils/util";

export default {
    name: "PaperList",
    data() {
        return {
            headers: [
                { text: "试卷名称", value: "name", align: "left", sortable: false, },
                { text: "类别", value: "type_text", align: "left", sortable: false, },
                { text: "级别", value: "level_text", align: "left", sortable: false, },
                { text: "操作", value: "operate", sortable: false, align: "center", width: 120},
            ],
            datas: [],
        };
    },
    mounted() {
    },
    activated() {
        this.loadData();
    },
    methods: {
        getTypeColor,
        getTypeFirstLetter,
        loadData() {
            getPapers().then((response) => {
                // console.log(response.data);
                this.datas = response.data;
            
            }).catch(function (err) {
                console.log(err);
            });
        },
        goto(id) {
            this.$router.push({path: '/exam-mgr/paper-list/question-list', query: {id: id}});
        },
        clickRow(item) {
            this.goto(item.paper_id);
        },

        openEditPaperDlg(data = null) {
            this.$refs.editPaperDlg.openDlg(data);
        },
        openConfirmDlg(paperId) {
            this.$refs.delPaperConfirmDlg.openDlg(paperId);
        },

        delPaperConfirm(paperId) {
            delPaper(paperId).then((response) => {
                // console.log(response.data)
                if (response.msg == "SUCCESS") {
                    for (let i = 0; i < this.datas.length; i++) {
                        if (this.datas[i].paper_id == paperId) {
                            this.datas.splice(i, 1)
                            break
                        }
                    }
                    this.$store.dispatch("web/SetAlert", { type: "success", msg: "删除试卷成功" });

                } else {
                    this.$store.dispatch("web/SetAlert", { type: "error", msg: "删除试卷失败" });
                }
            }).catch(function (err) {
                console.log(err);
            });
        },
        submitPaper(paper) {
            editPaper(paper.paper_id, paper.name, paper.type, paper.level).then((response) => {
                // console.log(response.data)
                if (response.msg == 'SUCCESS') {
                    this.$store.dispatch('web/SetAlert', {type: "success", msg: "创建试卷成功"});

                    // 更新列表数据
                    let newData = response.data
                    if (paper.paper_id == '') {
                        this.datas.unshift(newData)
                    } else {
                        for (let i in this.datas) {
                            if (this.datas[i].paper_id == paper.paper_id) {
                                this.datas[i] = newData
                                this.$set(this.datas, i, this.datas[i]);
                                break
                            }
                        }
                    }

                } else {
                    this.$store.dispatch('web/SetAlert', {type: "error", msg: "创建试卷失败"});
                }
            }).catch(function (err) {
                console.log(err);
            });
        },
    },
    components: {
        EditPaperDlg,
        ConfirmDlg,
    }
};
</script>

<style lang="scss" scoped>
.option-area {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .option-item {
        width: 150px;
    }
}
.btn-font {
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1px;
    margin: 0;
}

.add-btn-row {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
}

.td-line {
    text-align: left;
    display: flex;
    justify-content: flex-start;
    height: unset !important;
    .active-name {
        padding: 8px 25px;
        border-radius: 50px;
        // background-color: #efefef;
        background-color: #fff;
        &:hover {
            cursor: pointer;
            background-color: #fff;
            border-radius: 50px;
        }
    }
    .active-hover {
        cursor: pointer;
    }
}
</style>

<!-- 让v-data-table行中的cursor显示pointer -->
<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
